<template>
  <b-button
    v-if="currentUser && currentUser.subscription_days_left"
    class="d-none d-lg-block"
    style="line-height: 22.5px !important"
    :variant="'outline-default'"
    :to="{ name: 'payments-settings' }"
    >{{
      `Осталось ${currentUser.subscription_days_left} ${literal.declension(
        currentUser.subscription_days_left,
        'дн'
      )} подписки`
    }}</b-button
  >
  <b-button
    v-else
    href="https://sub.easyprbot.com/"
    target="_blank"
    class="d-none d-lg-block"
    variant="yellow"
    style="line-height: 22.5px !important"
    >Купить подписку на сайт</b-button
  >
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['currentUser']),
  },
};
</script>
